import React, { FC, useMemo } from 'react';

import { useClassnames } from 'hook/use-classnames';
import OkIcon from 'component/icon/ok';
import CloseIcon from 'component/icon/close';

import { IProps } from './types';
import style from './style.pcss';

const Status: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);

    const elIcon = useMemo(() => {
        if(props.progress === 100 && props.isDone) {
            return <OkIcon className={cn('status__icon')} />;
        }

        if(props.isError) {
            return <CloseIcon className={cn('status__icon')} />;
        }
    }, [props.progress, props.isDone, props.isError]);

    return (
        <div className={cn('status')} data-tip={props.tooltip}>
            <span
                className={cn('status__fill', {
                    'status__fill_error': props.isError,
                    'status__fill_uploaded': props.isAlreadyUploaded
                })}
                style={{ width: `${props.progress}%` }}
            >
                {elIcon}
            </span>
        </div>
    );
};

export default Status;
