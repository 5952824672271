import { AxiosResponse } from 'axios';
import { Page, PaginationResponse } from '../base';
import httpClient from 'src/utils/http-сlient';
import {
    AlbumFilterValuesItem,
    AlbumItem,
    AlbumListItem,
    AlbumPerson,
    AlbumPhoto,
    AlbumSimpleListItem,
    AlbumsAPIType,
    BulkUpdateAlbumPhotos,
    BulkUpdateAlbumPhotosData,
    CreateAlbum,
    CreateAlbumItem,
    GetAlbumAlbumsListData,
    GetAlbumFilterValuesData,
    GetAlbumListData,
    GetAlbumPersonData,
    GetAlbumPhotoData,
    GetAlbumSimpleListData,
    PartialUpdateAlbumData
} from './types';
import queryString from 'query-string';

const getAlbumList = (
    page: Page,
    data?: GetAlbumListData
): Promise<AxiosResponse<PaginationResponse<AlbumListItem>>> => {
    return httpClient.get<PaginationResponse<AlbumListItem>>(
        '/api/v2/albums/',
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...data
            }
        }
    );
};

const createAlbum = (
    data: CreateAlbum
): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.post<CreateAlbumItem>('/api/v2/albums/', data);
};

const getAlbumAlbumsList = (
    album_pk: number,
    page: Page,
    data?: GetAlbumAlbumsListData
): Promise<AxiosResponse<PaginationResponse<AlbumListItem>>> => {
    return httpClient.get<PaginationResponse<AlbumListItem>>(
        `/api/v2/albums/${album_pk}/albums/`,
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 10 },
                ...data
            }
        }
    );
};

const createAlbumAlbumsItem = (
    album_pk: number,
    data: CreateAlbum
): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(`/api/v2/albums/${album_pk}/albums/`, data);
};

const getAlbumPersonList = (
    album_pk: number,
    page: Page,
    data?: GetAlbumPersonData
): Promise<AxiosResponse<PaginationResponse<AlbumPerson>>> => {
    return httpClient.get<PaginationResponse<AlbumPerson>>(
        `/api/v2/albums/${album_pk}/persons/`,
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...data
            },
            paramsSerializer(params) {
                return queryString.stringify(params, { arrayFormat: 'none' });
            }
        }
    );
};

const getAlbumPhotoList = (
    album_pk: number,
    page: Page,
    data?: GetAlbumPhotoData
): Promise<AxiosResponse<PaginationResponse<AlbumPhoto>>> => {
    return httpClient.get<PaginationResponse<AlbumPhoto>>(
        `/api/v2/albums/${album_pk}/photos/`,
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...data
            },
            paramsSerializer(params) {
                return queryString.stringify(params, { arrayFormat: 'none' });
            }
        }
    );
};

const getAlbumRetrieve = (id: number): Promise<AxiosResponse<AlbumItem>> => {
    return httpClient.get<AlbumItem>(`/api/v2/albums/${id}/`);
};

const getAlbumAlbumsRetrieve = (
    album_pk: number,
    id: number
): Promise<AxiosResponse<AlbumItem>> => {
    return httpClient.get<AlbumItem>(
        `/api/v2/albums/${album_pk}/albums/${id}/`
    );
};

const updateAlbum = (
    id: number,
    data: CreateAlbum
): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.put<CreateAlbumItem>(`/api/v2/albums/${id}/`, data);
};
const updateAlbumAlbums = (
    album_pk: number,
    id: number,
    data: CreateAlbum
): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.put<CreateAlbumItem>(
        `/api/v2/albums/${album_pk}/albums/${id}/`,
        data
    );
};

const partialUpdateAlbum = (
    id: number,
    data?: PartialUpdateAlbumData
): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.patch<CreateAlbumItem>(`/api/v2/albums/${id}/`, data);
};

const partialUpdateAlbumAlbums = (
    album_pk: number,
    id: number,
    data?: PartialUpdateAlbumData
): Promise<AxiosResponse<CreateAlbumItem>> => {
    return httpClient.patch<CreateAlbumItem>(
        `/api/v2/albums/${album_pk}/albums/${id}/`,
        data
    );
};

const destroyAlbum = (id: number): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(`/api/v2/albums/${id}/`);
};

const destroyAlbumAlbums = (
    album_pk: number,
    id: number
): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(`/api/v2/albums/${album_pk}/albums/${id}/`);
};

const bulkUpdateAlbumPhotos = (
    id: number,
    data: BulkUpdateAlbumPhotosData
): Promise<AxiosResponse<BulkUpdateAlbumPhotos>> => {
    return httpClient.put<BulkUpdateAlbumPhotos>(
        `/api/v2/albums/${id}/bulk-photo-update/`,
        data
    );
};

const bulkUpdateAlbumAlbumsPhotos = (
    album_pk: number,
    id: number,
    data: BulkUpdateAlbumPhotosData
): Promise<AxiosResponse<BulkUpdateAlbumPhotos>> => {
    return httpClient.put<BulkUpdateAlbumPhotos>(
        `/api/v2/albums/${album_pk}/albums/${id}/bulk-photo-update/`,
        data
    );
};

const getAlbumFilterValues = (
    data?: GetAlbumFilterValuesData
): Promise<AxiosResponse<Array<AlbumSimpleListItem>>> => {
    return httpClient.get<Array<AlbumSimpleListItem>>(
        '/api/v2/albums/filter-values/',
        {
            params: {
                ...data
            }
        }
    );
};

const getAlbumSimpleList = (
    page: Page,
    data?: GetAlbumSimpleListData
): Promise<AxiosResponse<PaginationResponse<AlbumFilterValuesItem>>> => {
    return httpClient.get<PaginationResponse<AlbumFilterValuesItem>>(
        '/api/v2/albums/simple/',
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...data
            }
        }
    );
};

export default {
    getAlbumList,
    createAlbum,
    getAlbumAlbumsList,
    createAlbumAlbumsItem,
    getAlbumPersonList,
    getAlbumPhotoList,
    getAlbumRetrieve,
    getAlbumAlbumsRetrieve,
    updateAlbum,
    updateAlbumAlbums,
    partialUpdateAlbum,
    partialUpdateAlbumAlbums,
    destroyAlbum,
    destroyAlbumAlbums,
    bulkUpdateAlbumPhotos,
    bulkUpdateAlbumAlbumsPhotos,
    getAlbumFilterValues,
    getAlbumSimpleList
} as AlbumsAPIType;
